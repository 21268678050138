<template>
  <div v-if="isMobile" class="h-screen flex justify-center">
    <div class="px-8 py-16">
      <PatchboxLogo />
      <p class="mt-5 mb-8">Unfortunately, our Rack Planner is not yet available on mobile devices and can only be used on desktop devices. We apologize for this inconvenience.</p>
      <p><a href="https://patchbox.com" target="_blank" class="primary-button">Go to patchbox.com</a></p>
    </div>
  </div>
  <NuxtPage v-else />
</template>

<script setup>
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | Network Rack Planner | PATCHBOX` : 'Free online 19" Rack Planning Tool | Network Rack Planner | PATCHBOX';
  }
})

const hasTouchScreen = () => {
  let res = false;
  if ('maxTouchPoints' in navigator) res = navigator.maxTouchPoints > 0;
  else if ('msMaxTouchPoints' in navigator) res = navigator.msMaxTouchPoints > 0;
  else {
    const mQ = matchMedia?.('(pointer:coarse)');
    if (mQ?.media === '(pointer:coarse)') res = !!mQ.matches;
    else if ('orientation' in window) res = true;
    else res = /\b(Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini)\b/i.test(navigator.userAgent);
  }
  return res;
}

const isMobile = hasTouchScreen() && window.innerWidth < 1024;
</script>